import React, { useEffect } from 'react';
import { globalHistory } from '@reach/router'
import Generic from 'components/Layouts/Generic';
import recitSrc from 'static/logos/recit@2x.png';
import recitUniversSrc from 'static/logos/recit_univers@2x.png';
import banqSrc from 'static/logos/banq@2x.png';
import quebecorSrc from 'static/logos/quebecor@2x.png';
import gtmEvents from '../utils/functions/gtmFunctions.js';

const title = 'Partenaires et remerciements';

const body = `
  <p><i>Les Lignes du temps du Québec</i> sont le résultat d’un travail collaboratif constant avec une multitude
  de partenaires et de collaborateurs.</p>
  <div>
    <div><img src=${recitSrc}></div>
    <div><img src=${recitUniversSrc}></div>
  </div>
  <h2 style="font-size: 24px; margin-top: 20px; margin-bottom: 20px !important;">Remerciements</h2>
  <p>Ce projet a été réalisé avec le généreux soutien de la Fondation de BAnQ et de Québecor.</p>
  <div>
    <div><img src=${banqSrc}></div>
    <div><img src=${quebecorSrc}></div>
  </div>
`;

const Partenaires = (props) => {
    useEffect(() => {
      gtmEvents.sendVirtualPageView(title, globalHistory.location.href.href, globalHistory.location.pathname);
  }, []);
  return <Generic location={props?.location} title={title} body={body} />;
};

export default Partenaires;
